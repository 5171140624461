import { AppletManifestConfig, AppletName } from "./types";
import { id } from "./utils";

import * as NotificationsApplet from "~/Applet/Notifications";
import * as SeoMetaApplet from "~/Applet/SeoMeta";

export const APPLET_MANIFEST: Record<AppletName, AppletManifestConfig> = {
  _template: {
    mount: async (el) => (await import("./Applet/_template/index")).mount(el),
    mountId: id("template"),
    themeName: undefined,
    useModalWidth: () => "inherit",
  },
  "ant-theme-editor": {
    mount: async (el) =>
      (await import("./Applet/AntThemeEditor/index")).mount(el),
    mountId: id("ant-theme-editor"),
    themeName: "EquipmentShare",
    useModalWidth: () => "95vw",
  },
  notifications: {
    // do not lazy load
    mount: async (el) => NotificationsApplet.mount(el),
    mountId: id("notifications"),
    themeName: "EquipmentShare",
    useModalWidth: () => "inherit",
  },
  "article-carousel": {
    mount: async (el) =>
      (await import("./Applet/ArticleCarousel/index")).mount(el),
    mountId: id("article-carousel"),
    themeName: "EquipmentShare",
    useModalWidth: () => "inherit",
  },
  "asset-retail-quote": {
    mount: async (el) =>
      (await import("./Applet/AssetRetailQuote/index")).mount(el),
    mountId: id("asset-retail-quote"),
    themeName: "EquipmentShare",
    useModalWidth: () => "inherit",
  },
  "fuel-delivery-accordion": {
    mount: async (el) =>
      (await import("./Applet/FuelDeliveryAccordion/index")).mount(el),
    mountId: id("fuel-delivery-accordion"),
    themeName: "EquipmentShare",
    useModalWidth: () => "inherit",
  },
  "job-posting": {
    mount: async (el) => (await import("./Applet/JobPosting/index")).mount(el),
    mountId: id("job-posting"),
    themeName: "EquipmentShare",
    useModalWidth: () => "inherit",
  },
  "job-board": {
    mount: async (el) => (await import("./Applet/JobBoard/index")).mount(el),
    mountId: id("job-board"),
    themeName: "EquipmentShare",
    useModalWidth: () => "inherit",
  },
  "location-scorecard": {
    mount: async (el) =>
      (await import("./Applet/LocationScorecard/index")).mount(el),
    mountId: id("location-scorecard"),
    themeName: undefined,
    useModalWidth: () => "inherit",
  },
  "rental-services-accordion": {
    mount: async (el) =>
      (await import("./Applet/RentalServicesAccordion/index")).mount(el),
    mountId: id("rental-services-accordion"),
    themeName: "EquipmentShare",
    useModalWidth: () => "inherit",
  },
  "locations-nearby": {
    mount: async (el) =>
      (await import("./Applet/LocationsNearby/index")).mount(el),
    mountId: id("locations-nearby"),
    themeName: "EquipmentShare",
    useModalWidth: () => "inherit",
  },
  "pump-cost-calculator": {
    mount: async (el) =>
      (await import("./Applet/PumpCostCalculator/index")).mount(el),
    mountId: id("pump-cost-calculator"),
    themeName: "EquipmentShare",
    useModalWidth: () => "80rem",
  },
  "contact-form": {
    mount: async (el) => (await import("./Applet/ContactForm/index")).mount(el),
    mountId: id("contact-form"),
    themeName: "EquipmentShare",
    useModalWidth: () => "50rem",
  },
  "rental-sales-lead-generator": {
    mount: async (el) =>
      (await import("./Applet/RentalSalesLeadGenerator/index")).mount(el),
    mountId: id("rental-sales-lead-generator"),
    themeName: "EquipmentShare",
    useModalWidth: () => "50rem",
  },
  "request-service": {
    mount: async (el) =>
      (await import("./Applet/RequestService/index")).mount(el),
    mountId: id("request-service"),
    themeName: "EquipmentShare",
    useModalWidth: () => "50rem",
  },
  "release-notes": {
    mount: async (el) =>
      (await import("./Applet/ReleaseNotes/index")).mount(el),
    mountId: id("release-notes"),
    themeName: "EquipmentShare",
    useModalWidth: () => "50rem",
  },
  "shopify-featured-products": {
    mount: async (el) =>
      (await import("./Applet/ShopifyFeaturedProducts/index")).mount(el),
    mountId: id("shopify-featured-products"),
    themeName: "EquipmentShare",
    useModalWidth: () => "50rem",
  },
  "t3-kits": {
    mount: async (el) => (await import("./Applet/T3Kits/index")).mount(el),
    mountId: id("t3-kits"),
    themeName: "T3",
    useModalWidth: () => "50rem",
  },
  "t3-lead-generator": {
    mount: async (el) =>
      (await import("./Applet/T3LeadGenerator/index")).mount(el),
    mountId: id("t3-lead-generator"),
    themeName: "T3",
    useModalWidth: () => "50rem",
  },
  "seo-meta": {
    // do not lazy load
    mount: async (el) => SeoMetaApplet.mount(el),
    mountId: id("seo-meta"),
    themeName: "EquipmentShare",
    useModalWidth: () => "inherit",
  },
  pointcast: {
    mount: async (el) => (await import("./Applet/Pointcast/index")).mount(el),
    mountId: id("pointcast"),
    themeName: undefined,
    useModalWidth: () => "inherit",
  },
};
