import React, { useEffect, useState } from "react";
import { Article, WithContext } from "schema-dts";

import { JSONLDComponent } from "../JSONLDComponent";

import { PressJson } from "./types";
import { buildStructuredData } from "./utils";

import { useOmnichannelApi } from "~/OmnichannelApi";

export const PressSchema: React.FC = () => {
  const client = useOmnichannelApi();

  const [pressData, setPressData] = useState<WithContext<Article>>();

  useEffect(() => {
    const matches = window.location.pathname.match(
      /^\/press-releases\/([^/]+)/i
    );

    if (matches) {
      const [, item_slug] = matches;

      client["static.webflow.collections.items.get"]({
        collection_slug: "press-releases",
        item_slug,
        file_type: "json",
      }).then(({ data }) => {
        setPressData(buildStructuredData(data as PressJson));
      });
    }
  }, []);

  if (!pressData) {
    return null;
  }

  return <JSONLDComponent structuredData={pressData} />;
};
