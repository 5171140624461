import { Alert, ConfigProvider, theme } from "antd";
import enUS from "antd/locale/en_US";
import React, { PropsWithChildren, useEffect } from "react";

import { AppletWrapperProps } from "./types";

import { APPLET_MANIFEST } from "~/AppletManifest";
import { GlobalStyles, themeConfig } from "~/Constant/Theme";
import { isDevelopment, isProduction } from "~/env";

/**
 * Applets should be wrapped in this component to automatically apply CSS
 */
export const AppletWrapper: React.FC<PropsWithChildren<AppletWrapperProps>> = ({
  children,
  appletName,
  reset,
}) => {
  const { themeName } = APPLET_MANIFEST[appletName];

  return (
    <Alert.ErrorBoundary
      message={isProduction ? "Something went wrong!" : undefined}
      description={
        isProduction
          ? "Our engineers have been notified.  Please try again or contact us if you continue to experience issues."
          : undefined
      }
    >
      <ConfigProvider
        theme={themeName ? themeConfig[themeName] : undefined}
        locale={enUS}
      >
        <GlobalStyles $reset={reset} $themeName={themeName}>
          <div className="fadeInFast">{children}</div>
        </GlobalStyles>
        {isDevelopment && <ConsoleLogTheme />}
      </ConfigProvider>
    </Alert.ErrorBoundary>
  );
};

const ConsoleLogTheme: React.FC = () => {
  const { token } = theme.useToken();

  useEffect(() => {
    console.log("[dev] const { token } = theme.useToken()", token);
  }, [token]);

  return null;
};
