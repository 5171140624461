import { Auth0ProviderOptions } from "@equipmentshare/auth0-react";

import { RGB } from "./types";

export const isTruthy = <T,>(
  value: T
): value is T extends null | undefined | false ? never : T =>
  value !== null && value !== undefined && value !== false;

export const toTitleCase = (str: string) =>
  str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );

export const stripHtmlTags = (str?: string) =>
  (str ?? "").replace(/<[^>]*>/g, "");

export const id = (appletName: string): string =>
  `omnichannel-applet-${appletName}`;

export const modalizeId = (mountId: string, modal: boolean): string =>
  mountId + (modal ? "-modal" : "");

export const className = (componentName: string): string =>
  `omnichannel-component-${componentName}`;

export const onRedirectCallback: Auth0ProviderOptions["onRedirectCallback"] = (
  appState
) => {
  history.replaceState(
    null,
    "",
    appState && appState.returnTo ? appState.returnTo : window.location.href
  );
};

/**
 * Create a hex color computed from an input string value
 */
export const stringToColor = (str: string): string => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

export const blackWhiteContrastColor = (hex: string) => {
  const WHITE = "#FFFFFF";
  const BLACK = "#000000";

  const white = contrast(WHITE, hex);
  const black = contrast(BLACK, hex);

  return white > black ? WHITE : BLACK;
};

export const hexToRgb = (hex: string): RGB => {
  // Expand shorthand form (e.g. '03F') to full form (e.g. '0033FF')
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

  hex = hex.replace(shorthandRegex, (_, r, g, b) => r + r + g + g + b + b);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (result) {
    const [, r, g, b] = result;

    return {
      r: parseInt(r, 16),
      g: parseInt(g, 16),
      b: parseInt(b, 16),
    };
  }

  throw new Error(`${hex} is not a valid hexidecimal color code!`);
};

export const luminance = ({ r, g, b }: RGB) => {
  const [R, G, B] = [r, g, b].map((v: any) => {
    v /= 255;

    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });

  return R * 0.2126 + G * 0.7152 + B * 0.0722;
};

export const contrast = (foreground: string, background: string) => {
  const rgb1 = hexToRgb(foreground);
  const rgb2 = hexToRgb(background);

  const lum1 = luminance(rgb1);
  const lum2 = luminance(rgb2);

  const ratio = (Math.max(lum1, lum2) + 0.05) / (Math.min(lum1, lum2) + 0.05);

  return Math.round(ratio * 100) / 100;
};
