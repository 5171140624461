import React, { useEffect, useState } from "react";
import { AggregateOffer, WithContext } from "schema-dts";

import { JSONLDComponent } from "../JSONLDComponent";

import { CategoryJson } from "./types";
import { buildStructuredData } from "./utils";

import { useOmnichannelApi } from "~/OmnichannelApi";

export const AggregateOfferSchema: React.FC = () => {
  const client = useOmnichannelApi();

  const [aggregateOfferData, setAggregateOfferData] =
    useState<WithContext<AggregateOffer>>();

  useEffect(() => {
    const matches = window.location.pathname.match(
      /^\/rent\/categories\/([^/]+)/i
    );

    if (matches) {
      const [, item_slug] = matches;

      client["static.webflow.collections.items.get"]({
        collection_slug: "categories",
        item_slug,
        file_type: "json",
      }).then(({ data }) => {
        setAggregateOfferData(buildStructuredData(data as CategoryJson));
      });
    }
  }, []);

  if (!aggregateOfferData) {
    return null;
  }

  return <JSONLDComponent structuredData={aggregateOfferData} />;
};
